<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Рассылки</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Просмотр рассылки</h5>
        </div>
      </div>

      <div class="card-body">
        <div class="card-body">
          <a-form-model>
            <a-form-model-item label="Тип" required>
              <a-select v-if="notice.status" placeholder="выберите статус" :default-value="[notice.status]" disabled>
                <a-select-option v-for="(status, index) in statuses" :key="index">
                  {{ status }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="Получатели">
              <a-select
                v-if="users.length"
                :default-value="[...users.map(u => u.id)]"
                :default-active-first-option="false"
                disabled
                multiple="true"
                mode="multiple"
                style="width: 100%"
              >
                <a-select-option v-for="(user, index) in users" :key="index" :value="user.id">
                  {{ user.first_name }} {{ user.last_name }} {{ user.email }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item ref="active" label="Тема(ru)">
              <a-input :value="notice.title" disabled />
            </a-form-model-item>
            <!--<a-form-model-item ref="active" label="Тема(ua)">
              <a-input v-model.number="form.ua.title"/>
            </a-form-model-item>-->
            <a-form-model-item ref="active" label="Содержимое(ru)">
              <a-input :value="notice.name" disabled />
            </a-form-model-item>
            <!--<a-form-model-item ref="active" label="Содержимое(ua)">
              <a-input v-model.number="form.ua.name"/>
            </a-form-model-item>-->
          </a-form-model>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'id',

  data() {
    return {
      users: [],
      notice: {},
      statuses: [],
    }
  },

  async created() {
    const { data: { data: { notice } } } = await this.$services.get(`admin/notice/edit/${this.$route.params.id}`)
    this.notice = notice
    this.users.push(this.notice.recipient)
    this.statuses.push(this.notice.status)
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
